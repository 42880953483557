<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat dense color="grey lighten-3">
        <v-text-field
          flat
          solo
          dense
          hide-no-data
          hide-details
          append-icon="mdi-magnify"
          label="Gast suchen..."
          v-model="searchInput"
          class="mr-2"
        />
        <v-btn icon dark color="success" @click="createGuest">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear v-if="!loaded" :indeterminate="true" />
      <recycle-scroller
        v-else-if="filteredGuests.length"
        page-mode
        :items="filteredGuests"
        :item-size="44"
        :buffer="2"
        key-field="id"
      >
        <person-tile
          slot-scope="{ item }"
          :person="item"
          :key="item.id"
          @click="showDialog"
        />
      </recycle-scroller>
      <v-card-text v-else>Keine Gäste gefunden.</v-card-text>
    </v-card>

    <person-detail-dialog
      v-model="showDetailDialog"
      :person="dialogItem"
      @edit="showDialog({ type: 'edit', id: dialogItem.id })"
    />
    <person-edit-dialog
      v-model="showEditDialog"
      :person="dialogItem"
      @save="saveGuest"
      @delete="deleteGuest"
    />
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'
import fuzzy from 'fuzzy'
import clone from 'lodash/clone'

import PersonTile from '@/components/person/PersonTile'
import PersonDetailDialog from '@/components/person/PersonDetailDialog.vue'
import PersonEditDialog from '@/components/person/PersonEditDialog.vue'

import Confirm from '@/components/Confirm'

import { handleApiError, createNotification } from '@/utils.js'
import { usePersonStore } from '@/stores/person'

const EMPTY_GUEST = {
  id: undefined,
  type: 'guest',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  ice_name: '',
  ice_relation: '',
  ice_phone: '',
}

export default {
  name: 'Guests',
  components: { PersonTile, Confirm, PersonDetailDialog, PersonEditDialog },
  props: {},
  data() {
    return {
      searchInput: '',
      loaded: false,
      showDetailDialog: false,
      showEditDialog: false,
      dialogItem: {},
    }
  },
  computed: {
    filteredGuests() {
      const inp = this.searchInput.trim()
      let matches = this.guests
      if (inp) {
        matches = fuzzy
          .filter(inp, this.guests, { extract: el => el.name })
          .map(e => e.original)
      }
      return matches
    },
    ...mapState(usePersonStore, { guests: 'guestList' }),
  },
  mounted() {
    this.loaded = false
    usePersonStore().fetchPersons()
      .then(() => {
        this.loaded = true
      })
      .catch(error => {
        handleApiError(error, 'Fehler beim Laden der Daten.')
      })
  },
  methods: {
    showDialog(event) {
      this.showEditDialog = false
      this.showDetailDialog = false

      const promise = usePersonStore().getPerson(event.id)
      if (event.type === 'detail') {
        promise
          .then(guest => {
            this.dialogItem = guest
            this.showDetailDialog = true
          })
          .catch(e => handleApiError(e, 'Fehler beim Laden der Daten'))
      } else if (event.type === 'edit') {
        promise
          .then(guest => {
            this.dialogItem = guest
            this.showEditDialog = true
          })
          .catch(e => handleApiError(e, 'Fehler beim Laden der Daten'))
      }
    },
    saveGuest(guest) {
      usePersonStore().updateOrCreate(guest)
        .then(guest => {
          createNotification(`Gast "${guest.name}" gespeichert`, 'success')
          this.showEditDialog = false
        })
        .catch(e => handleApiError(e, 'Fehler beim Speichern'))
    },
    deleteGuest(guest) {
      const txt = `${guest.name} unwiderruflich löschen? Ist dieser Gast nun SAC Mitglied? In diesem Fall könenn die Daten unter "Tools" migriert werden.`
      this.$refs.confirm
        .open('Löschen bestätigen', txt, { color: 'error' })
        .then(confirm => {
          if (confirm) {
            usePersonStore().deletePerson(guest)
              .then(() => {
                createNotification('Gast gelöscht', 'success')
                this.showEditDialog = false
              })
              .catch(e => handleApiError(e, 'Fehler beim Löschen'))
          }
        })
    },
    createGuest() {
      this.dialogItem = clone(EMPTY_GUEST)
      this.showEditDialog = true
    },
  },
}
</script>


<style lang="scss">
.virtual-scroller {
  padding: 8px 0 8px 0;

  .v-list {
    padding: 0;
  }
}
</style>
